import { IMultiLang, multiLangFromJson } from "../../../core/models";

export interface IBenefit {
  id: string;
  title: IMultiLang;
  description: IMultiLang;
}

export const benefitFromJson = (json: any): IBenefit => {
  const e: IBenefit = {
    id: `${json.id}`,
    title: multiLangFromJson(json, "title"),
    description: multiLangFromJson(json, "description"),
  };

  return e;
};
