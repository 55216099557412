import { IImage, generateImage } from "../../../core/image";
import { IMultiLang, multiLangFromJson } from "../../../core/models";
import { BASE_URL } from "../../../core/api_client";
import sortBy from "lodash/sortBy";
import { ECompass } from "../../floor/data/entities";

export interface IArea {
  id: string;
  name: IMultiLang;
  area: number;
  order: number;
}

const areaFromJson = (json: any): IArea => {
  const e: IArea = {
    id: `${json?.id}`,
    order: parseFloat(json.order_number),
    area: json.square,
    name: multiLangFromJson(json.area, "name"),
  };

  return e;
};

export enum ERepairType {
  one = "1",
  two = "2",
}

export interface IApartment {
  image: IImage;
  apartmentNumber: string;
  roomCount: number;
  internalSize: number;
  externalSize: number;
  repairType: ERepairType;
  floorId: string;
  floorNumber: string;
  entranceNumber: string;
  price: number;
  discountPrice: number;
  areas: IArea[];
  sectorId: string;
  fileUrl: string;
  compass: ECompass;
  buildingNumber: number;
}

export const apartmentFromJson = (json: any): IApartment => {
  const areas: IArea[] = json?.apartment_type?.rooms?.map(areaFromJson);

  const e: IApartment = {
    image: generateImage(json?.apartment_type),
    entranceNumber: json?.apartment?.entrance_number,
    apartmentNumber: json?.apartment?.apartment_number,
    internalSize: json?.apartment_type?.internal_size,
    externalSize: json?.apartment_type?.external_size,
    repairType: json?.apartment?.repair_type === "2" ? ERepairType.two : ERepairType.one,
    roomCount: json?.apartment_type?.count_of_rooms,
    floorId: json?.apartment?.floor_id,
    floorNumber: json?.apartment?.floor_number,
    price: json?.apartment?.price,
    discountPrice: json?.apartment?.discount_price,
    fileUrl: `${BASE_URL}/${json?.apartment?.directory}/${json?.apartment?.file_name}`,
    areas: sortBy(areas, ["order"]),
    sectorId: json?.apartment?.project_plan_sector_plan_id,
    compass: json?.compass_id,
    buildingNumber: json?.building_number,
  };

  return e;
};
