import React from "react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import * as queryString from "query-string";
import { Link, useLocation, LinkProps } from "react-router-dom";
import { History } from "history";

import aboutAz from "./modules/about/ui/translations/az.json";
import aboutEn from "./modules/about/ui/translations/en.json";
import aboutRu from "./modules/about/ui/translations/ru.json";

import benefitsAz from "./modules/benefits/ui/translations/az.json";
import benefitsEn from "./modules/benefits/ui/translations/en.json";
import benefitsRu from "./modules/benefits/ui/translations/ru.json";

import galleryAz from "./modules/gallery/ui/translations/az.json";
import galleryEn from "./modules/gallery/ui/translations/en.json";
import galleryRu from "./modules/gallery/ui/translations/ru.json";

import conditionsAz from "./modules/conditions/ui/translations/az.json";
import conditionsEn from "./modules/conditions/ui/translations/en.json";
import conditionsRu from "./modules/conditions/ui/translations/ru.json";

import contactAz from "./modules/contact/ui/translations/az.json";
import contactEn from "./modules/contact/ui/translations/en.json";
import contactRu from "./modules/contact/ui/translations/ru.json";

import sectorAz from "./modules/sector/ui/translations/az.json";
import sectorEn from "./modules/sector/ui/translations/en.json";
import sectorRu from "./modules/sector/ui/translations/ru.json";

import projectAz from "./modules/project/ui/translations/az.json";
import projectEn from "./modules/project/ui/translations/en.json";
import projectRu from "./modules/project/ui/translations/ru.json";

import floorAz from "./modules/floor/ui/translations/az.json";
import floorEn from "./modules/floor/ui/translations/en.json";
import floorRu from "./modules/floor/ui/translations/ru.json";

import apartmentAz from "./modules/apartment/ui/translations/az.json";
import apartmentEn from "./modules/apartment/ui/translations/en.json";
import apartmentRu from "./modules/apartment/ui/translations/ru.json";

import layoutAz from "./components/layout/translations/az.json";
import layoutEn from "./components/layout/translations/en.json";
import layoutRu from "./components/layout/translations/ru.json";

import commonAz from "./translations/common/az.json";
import commonEn from "./translations/common/en.json";
import commonRu from "./translations/common/ru.json";

export enum ELanguage {
  az = "az",
  en = "en",
  ru = "ru",
}

function getLangFromUrl(): ELanguage {
  const parsed = queryString.parse(window.location.search);

  if (!parsed.lang) {
    return ELanguage.az;
  }

  if (Object.values(ELanguage).includes(parsed.lang as any)) {
    return parsed.lang as ELanguage;
  }

  return ELanguage.az;
}

export function changeLanguage(i18n: any, lang: ELanguage, history: History<any>) {
  const parsed = queryString.parse(window.location.search);
  parsed.lang = lang;

  document.documentElement.lang = lang;
  i18n.changeLanguage(lang);

  history.push({
    search: queryString.stringify(parsed),
  });
}

export function initI18n() {
  i18next.use(initReactI18next).init({
    resources: {
      az: {
        about: aboutAz,
        benefits: benefitsAz,
        gallery: galleryAz,
        conditions: conditionsAz,
        contact: contactAz,
        sector: sectorAz,
        project: projectAz,
        floor: floorAz,
        apartment: apartmentAz,
        layout: layoutAz,
        common: commonAz,
      },
      en: {
        about: aboutEn,
        benefits: benefitsEn,
        gallery: galleryEn,
        conditions: conditionsEn,
        contact: contactEn,
        sector: sectorEn,
        project: projectEn,
        floor: floorEn,
        apartment: apartmentEn,
        layout: layoutEn,
        common: commonEn,
      },
      ru: {
        about: aboutRu,
        benefits: benefitsRu,
        gallery: galleryRu,
        conditions: conditionsRu,
        contact: contactRu,
        sector: sectorRu,
        project: projectRu,
        floor: floorRu,
        apartment: apartmentRu,
        layout: layoutRu,
        common: commonRu,
      },
    },
    lng: getLangFromUrl(),
    fallbackLng: ELanguage.az,
    interpolation: {
      escapeValue: false,
    },
  });
}

interface IProps extends LinkProps {
  to: string;
  children: React.ReactNode;
}

export const ExtendedLink = ({ to, children, ...rest }: IProps) => {
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: to,
        search: location.search,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};
