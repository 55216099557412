import React, { Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Layout } from "./components/layout/layout";
import { ROUTES } from "./routes";
import { NotFound } from "./components/not_found/not_found";
import Helmet from "react-helmet";
import { initI18n } from "./i18n";
import { AboutDiscountsPage } from "./modules/apartment/ui/pages/about_discounts_page";
import { AboutRepairPage } from "./modules/apartment/ui/pages/about_repair_page";

const HomePage = React.lazy(() => import("./modules/home/ui/pages/home_page"));
const AboutPage = React.lazy(() => import("./modules/about/ui/pages/about_page"));
const BenefitsPage = React.lazy(() => import("./modules/benefits/ui/pages/benefits_page"));
const ProjectMainPage = React.lazy(() => import("./modules/project/ui/pages/main_page"));
const SectorMainPage = React.lazy(() => import("./modules/sector/ui/pages/main_page"));
const FloorMainPage = React.lazy(() => import("./modules/floor/ui/pages/main_page"));
const ApartmentMainPage = React.lazy(() => import("./modules/apartment/ui/pages/main_page"));
const ConditionsPage = React.lazy(() => import("./modules/conditions/ui/pages/conditions_page"));
const GalleryListPage = React.lazy(() => import("./modules/gallery/ui/pages/gallery_list_page"));
const ContactPage = React.lazy(() => import("./modules/contact/ui/pages/contact_page"));

initI18n();

export const Main: React.FC = () => {
  const location = useLocation();

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={`${window.location.host}${location.pathname}`} />
      </Helmet>

      <Suspense fallback={<div>loading..</div>}>
        <Switch>
          <Route path={ROUTES.HOME} exact>
            <HomePage />
          </Route>

          <Route path={ROUTES.ABOUT}>
            <AboutPage />
          </Route>

          <Route path={ROUTES.BENEFITS}>
            <BenefitsPage />
          </Route>

          <Route path={ROUTES.project}>
            <ProjectMainPage />
          </Route>

          <Route path={ROUTES.sector}>
            <SectorMainPage />
          </Route>

          <Route path={ROUTES.floor}>
            <FloorMainPage />
          </Route>

          <Route path={ROUTES.apartment}>
            <ApartmentMainPage />
          </Route>

          <Route path={ROUTES.CONTACT}>
            <ContactPage />
          </Route>

          <Route path={ROUTES.CONDITIONS}>
            <ConditionsPage />
          </Route>

          <Route path={`${ROUTES.GALLERY}`} exact>
            <GalleryListPage />
          </Route>

          <Route path={ROUTES.aboutDiscounts}>
            <AboutDiscountsPage />
          </Route>

          <Route path={ROUTES.aboutRepair}>
            <AboutRepairPage />
          </Route>

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
};
