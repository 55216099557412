import React from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import "./interior_carousel.scss";
import img1 from "../images/interior/1.jpg";
import img2 from "../images/interior/2.jpg";
import img3 from "../images/interior/3.jpg";
import img4 from "../images/interior/4.jpg";
import img5 from "../images/interior/5.jpg";
import img6 from "../images/interior/6.jpg";
import img7 from "../images/interior/7.jpg";
import img8 from "../images/interior/8.jpg";
import img9 from "../images/interior/9.jpg";
import img10 from "../images/interior/10.jpg";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../../theme/variables";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../routes";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

export const ModalHeader = styled(Modal.Header)`
  background-color: #97b997;
  display: flex;
  align-items: center;

  .modal-title {
    font-size: 1.1rem;
  }
`;

export const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 30px 40px;

  a {
    color: ${theme.colors.blue};
  }

  @media screen and (max-width: 700px) {
    padding: 30px 14px;
  }
`;

interface IProps extends ModalProps {}

export const InteriorDesignModal: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal dialogClassName="modal-90w" {...props}>
      <ModalHeader closeButton>
        <Modal.Title>{t("apartment:interiorModal.title")}</Modal.Title>
      </ModalHeader>
      <ModalContent>
        <p>
          {t("apartment:interiorModal.first")} <Link to={ROUTES.aboutRepair}>{t("apartment:interiorModal.link")}</Link>{" "}
          {t("apartment:interiorModal.second")}
        </p>

        <div className="interior_carousel">
          <Carousel prevIcon={<MdChevronLeft size={35} />} nextIcon={<MdChevronRight size={35} />} interval={null}>
            {images.map((img, i) => (
              <Carousel.Item key={i}>
                <img className="d-block w-100" src={img} alt="First slide" />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </ModalContent>
    </Modal>
  );
};
