import { IImage, generateImage } from "../../../core/image";
import { IShape, shapeFromJson } from "../../../components/editor/entites";
import { ERepairType } from "../../apartment/data/entities";
import { Maybe } from "../../../core/models";

export enum ECompass {
  N = "0",
  NE = "1",
  E = "2",
  SE = "3",
  S = "4",
  SW = "5",
  W = "6",
  NW = "7",
}

export enum EApartmentStatus {
  AVAILABLE = "AVAILABLE",
  SOLD = "SOLD",
  GOVERNMENT = "GOVERNMENT",
}

export interface IFloorApartment {
  id: string;
  shape: IShape;
  floorNumber: number;
  apartmentNumber: string;
  roomCount: number;
  internalSize: number;
  externalSize: number;
  repairType: ERepairType;
  etrance: string;
  status: EApartmentStatus;
}

export const floorApartmentFromJson = (json: any): IFloorApartment => {
  const id = `${json.id}`;

  const e: IFloorApartment = {
    id,
    shape: shapeFromJson(json, id),
    floorNumber: parseInt(json.floor_number),
    apartmentNumber: json?.apartment_number,
    internalSize: json?.internal_size,
    externalSize: json?.external_size,
    repairType: json?.repair_type === "2" ? ERepairType.two : ERepairType.one,
    roomCount: parseFloat(json?.count_of_rooms),
    etrance: json?.entrance_number,
    status: json?.status,
  };

  return e;
};

export interface IFloor {
  image: IImage;
  apartments: IFloorApartment[];
  number: number;
  sectorFloorCount: number;
  sectorName: string;
  compass: ECompass;
  nextFloorId: Maybe<number>;
  nextFloorNumber: string;
  prevFloorId: Maybe<number>;
  prevFloorNumber: string;
}

export const floorFromJson = (json: any): IFloor => {
  const apartments: IFloorApartment[] = json.apartments.map(floorApartmentFromJson);

  const e: IFloor = {
    image: generateImage(json),
    apartments,
    number: apartments?.[0]?.floorNumber,
    sectorName: json?.sector_name,
    sectorFloorCount: parseInt(json?.floor_count),
    compass: json?.compass_id,
    nextFloorId: json.next_floor || null,
    nextFloorNumber: json.next_floor_number,
    prevFloorId: json.prev_floor || null,
    prevFloorNumber: json.prev_floor_number,
  };

  return e;
};
