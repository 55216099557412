import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { ApartmentRepoImpl } from "../../data/repo";
import { IApartment } from "../../data/entities";

enum EApartmentActionType {
  GET_APARTEMENT_DETAIL = "GET_APARTEMENT_DETAIL",
}

export interface IApartmentReduxState {
  details: IAsyncData<IApartment>;
}

const getDetail = (id: string): IAsyncReduxAction<IApartment> => ({
  type: EApartmentActionType.GET_APARTEMENT_DETAIL,
  payload: ApartmentRepoImpl.getApartmentDetail(id),
});
const getDetailReducer = asyncItemReducerGenerator<IApartment>(EApartmentActionType.GET_APARTEMENT_DETAIL);

export const apartmentRedux = {
  actions: {
    getDetail,
  },
  reducers: {
    details: getDetailReducer,
  },
};

export const ApartmentReducers = combineReducers<IApartmentReduxState>({
  ...apartmentRedux.reducers,
});
