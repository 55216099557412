import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { AboutRepoImpl } from "../../data/repo";
import { IAbout } from "../../data/entities";

enum EAboutActionType {
  GET_ABOUT_LIST = "GET_ABOUT_LIST",
}

export interface IAboutReduxState {
  list: IAsyncData<IAbout[]>;
}

const getList = (): IAsyncReduxAction<IAbout[]> => ({
  type: EAboutActionType.GET_ABOUT_LIST,
  payload: AboutRepoImpl.getList(),
});
const getListReducer = asyncItemReducerGenerator<IAbout[]>(EAboutActionType.GET_ABOUT_LIST);

export const aboutRedux = {
  actions: {
    getList,
  },
  reducers: {
    list: getListReducer,
  },
};

export const AboutReducers = combineReducers<IAboutReduxState>({
  ...aboutRedux.reducers,
});
