import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { benefitRedux } from "../../../benefits/ui/state/state";
import { IAppReduxState } from "../../../../redux/store";
import { IAsyncData } from "../../../../core/models";
import { IBenefit } from "../../../benefits/data/entities";
import { BackImage } from "../../../../components/back_image/back_image";
import { Page } from "../../../../components/page/page";
import { Container } from "../../../../components/container";
import { PageTitle } from "../../../../components/page_title";
import styled from "styled-components";
import { TransparentButton } from "../../../../components/transparent_button";
import { theme } from "../../../../../theme/variables";
import { FiPlayCircle } from "react-icons/fi";
import { RepairTypeVideoModal } from "../components/repair_type_video_modal";

const StyledBackButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.yellow};
  padding: 14px 30px 14px 20px;
  margin-bottom: 30px;
  border-radius: 33px;

  p {
    font-size: 16px;
    margin: 0;
    margin-left: 2px;
  }

  @media screen and (min-width: 992px) {
    left: 20px;
    top: 120px;
  }
`;

const StyledContainer = styled(Container)`
  padding-bottom: 50px;

  li {
    color: ${theme.colors.yellow};
  }
`;

interface IProps {}

export const AboutRepairPage: React.FC<IProps> = ({}: IProps) => {
  const dispatch = useDispatch();
  const [isRepairVideoModalOpen, setIsRepairVideoModalOpen] = useState(false);

  function openRepairVideoModal() {
    setIsRepairVideoModalOpen(true);
  }

  function closeRepairVideoModal() {
    setIsRepairVideoModalOpen(false);
  }

  useEffect(() => {
    dispatch(benefitRedux.actions.getList());
  }, [dispatch]);
  const benefitListBranch = useSelector<IAppReduxState, IAsyncData<IBenefit[]>>((state) => state.benefit.list);
  const repair = benefitListBranch.data?.find((b) => b.id === "7");

  return (
    <div>
      <BackImage />
      <Page>
        <StyledContainer>
          <PageTitle>Təmir haqqında</PageTitle>
          <p>Mənzillərin təmiri</p>
          <StyledBackButton onClick={openRepairVideoModal}>
            <FiPlayCircle size={24} />
            <p>Video görüntü</p>
          </StyledBackButton>
          {repair?.description && <div dangerouslySetInnerHTML={{ __html: repair.description.az }} />}
        </StyledContainer>

        <RepairTypeVideoModal show={isRepairVideoModalOpen} onHide={closeRepairVideoModal} />
      </Page>
    </div>
  );
};
