import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { ProjectRepoImpl } from "../../data/repo";
import { IProject, IPaginated, IProjectApartment, IProjectApartmentFilter } from "../../data/entities";

enum EProjectActionType {
  GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL",
  SEACRH_APARTMENTS = "SEACRH_APARTMENTS",
}

export interface IProjectReduxState {
  details: IAsyncData<IProject>;
  apartments: IAsyncData<IPaginated<IProjectApartment>>;
}

const getDetail = (): IAsyncReduxAction<IProject> => ({
  type: EProjectActionType.GET_PROJECT_DETAIL,
  payload: ProjectRepoImpl.getProjectDetail(),
});
const getDetailReducer = asyncItemReducerGenerator<IProject>(EProjectActionType.GET_PROJECT_DETAIL);

const searchApartments = (filter: IProjectApartmentFilter): IAsyncReduxAction<IPaginated<IProjectApartment>> => ({
  type: EProjectActionType.SEACRH_APARTMENTS,
  payload: ProjectRepoImpl.getProjectApartments(filter),
});
const searchApartmentsReducer = asyncItemReducerGenerator<IPaginated<IProjectApartment>>(
  EProjectActionType.SEACRH_APARTMENTS,
);

export const projectRedux = {
  actions: {
    getDetail,
    searchApartments,
  },
  reducers: {
    details: getDetailReducer,
    apartments: searchApartmentsReducer,
  },
};

export const ProjectReducers = combineReducers<IProjectReduxState>({
  ...projectRedux.reducers,
});
