import axios, { AxiosInstance } from "axios";

export interface ApiClient extends AxiosInstance {}

const currentDomain = window.location.hostname;

const prodDomain = "hovsan.mida.gov.az";
const stagingDomain = "mida-hovsan-front.now.sh";
const devDomain = "mida-hovsan-front.sinanbaymammadli.now.sh";

const prodApiUrl = "https://api-hovsan.mida.gov.az";
const stagingApiUrl = "https://csabroad.org/hovsan-api/public";
const devApiUrl = "https://csabroad.org/public";
const localApiUrl = "https://appdev.risk.az/sumqait";
// const localApiUrl = "http://172.30.60.201";

function getBaseApiUrl(): string {
  return prodApiUrl;
  if (currentDomain === prodDomain) {
    return prodApiUrl;
  } else if (currentDomain === stagingDomain) {
    return stagingApiUrl;
  } else if (currentDomain === devDomain) {
    return devApiUrl;
  }
  return localApiUrl;
}

export const BASE_URL = getBaseApiUrl();

export const apiClient: ApiClient = axios.create({
  baseURL: `${BASE_URL}/api/front`,
  headers: {
    "content-type": "application/json",
  },
});
