import * as React from "react";

export function PaintIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="31px" height="31px" viewBox="0 0 31 31" {...props}>
      <path
        d="M28.851238,20.7808785 L30.5458597,19.0862287 C31.1513801,18.4806982 31.1513801,17.4995342 30.5458597,16.8940037 L14.1058075,0.453618073 C13.8030776,0.151367483 13.4062592,0 13.0094408,0 C12.6126224,0 12.2158645,0.151367483 11.9136189,0.454162996 L7.52978667,4.8380075 C6.92426625,5.44353798 6.92426625,6.424702 7.52978667,7.03023248 L23.9698994,23.4706181 C24.2726293,23.7734136 24.6694477,23.9247811 25.0657212,23.9247811 C25.4619947,23.9247811 25.8592975,23.7734136 26.161543,23.4706181 L27.7554162,21.8767185 L29.1287203,23.2500454 L23.6371386,28.7416577 L12.9479866,18.0523282 C12.6451961,17.7495933 12.1548947,17.7495933 11.8521648,18.0523282 L8.67416639,21.2303794 L8.12598302,20.6821869 C7.82319253,20.3793914 7.42643467,20.2280239 7.03016118,20.2280239 C6.63388769,20.2280239 6.23658491,20.3793914 5.93433934,20.6821869 L0.45414032,26.161932 C-0.151380107,26.7674624 -0.151380107,27.7486265 0.45414032,28.3541569 L2.64632891,30.5463819 C2.94857448,30.8486325 3.34539289,31 3.74215075,31 C4.13890861,31 4.53572702,30.8486325 4.83797259,30.545837 L10.3181716,25.065547 C10.923692,24.4600165 10.923692,23.4788525 10.3181716,22.873322 L9.76998823,22.3251295 L12.3998032,19.6952709 L23.0889552,30.3846004 C23.2403202,30.5359678 23.4387294,30.6114094 23.6371386,30.6114094 C23.8355478,30.6114094 24.033957,30.5359678 24.185322,30.3846004 L30.7727255,23.7970875 C31.0754554,23.494292 31.0754554,23.0039824 30.7727255,22.7012475 L28.851238,20.7808785 Z M25.0595342,22.2564103 L8.74358974,5.94047847 L13.094312,1.58974359 L29.4102564,17.9056754 L25.0595342,22.2564103 Z M3.86088651,29.4102564 L1.58974359,27.1391135 L7.26731862,21.4615385 L9.53846154,23.7326814 L3.86088651,29.4102564 Z"
        id="Shape"
      ></path>
    </svg>
  );
}
