import { ApiClient, apiClient } from "../../../core/api_client";
import { IHomeSlider, homeSliderFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface IHomeSliderRepo {
  getList: () => Promise<IHomeSlider[]>;
}

const HomeSliderRepoImplFactory = (apiClient: ApiClient): IHomeSliderRepo => {
  const r: IHomeSliderRepo = {
    getList: async () => {
      try {
        const res = await apiClient.get(`/sliders`);
        return res.data.map(homeSliderFromJson);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const HomeSliderRepoImpl = HomeSliderRepoImplFactory(apiClient);
