import { IMultiLang, multiLangFromJson } from "../../../core/models";

export interface IAbout {
  id: string;
  description1: IMultiLang;
  title2: IMultiLang;
  description2: IMultiLang;
  title3: IMultiLang;
  description3: IMultiLang;
  title4: IMultiLang;
  description4: IMultiLang;
  title5: IMultiLang;
  description5: IMultiLang;
}

export const aboutFromJson = (json: any): IAbout => {
  const e: IAbout = {
    id: `${json.id}`,
    description1: multiLangFromJson(json, "description_1"),
    title2: multiLangFromJson(json, "title_2"),
    description2: multiLangFromJson(json, "description_2"),
    title3: multiLangFromJson(json, "title_3"),
    description3: multiLangFromJson(json, "description_3"),
    title4: multiLangFromJson(json, "title_4"),
    description4: multiLangFromJson(json, "description_4"),
    title5: multiLangFromJson(json, "title_5"),
    description5: multiLangFromJson(json, "description_5"),
  };

  return e;
};
