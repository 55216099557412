import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { HomeSliderRepoImpl } from "../../data/repo";
import { IHomeSlider } from "../../data/entities";

enum EHomeSliderActionType {
  GET_HOME_SLIDER_LIST = "GET_HOME_SLIDER_LIST",
}

export interface IHomeSliderReduxState {
  list: IAsyncData<IHomeSlider[]>;
}

const getList = (): IAsyncReduxAction<IHomeSlider[]> => ({
  type: EHomeSliderActionType.GET_HOME_SLIDER_LIST,
  payload: HomeSliderRepoImpl.getList(),
});
const getListReducer = asyncItemReducerGenerator<IHomeSlider[]>(EHomeSliderActionType.GET_HOME_SLIDER_LIST);

export const homeSliderRedux = {
  actions: {
    getList,
  },
  reducers: {
    list: getListReducer,
  },
};

export const HomeSliderReducers = combineReducers<IHomeSliderReduxState>({
  ...homeSliderRedux.reducers,
});
