import React, { useState } from "react";
import { StyledTransparentButton, FooterItem, FooterItemText } from "./apartment_actions";
import { CalculatorIcon } from "../images/calculator_icon";
import { useTranslation } from "react-i18next";
import { MortgageCalculatorModal } from "./mortgage_calculator_modal";

interface IProps {}

export const MortgageCalculatorButton: React.FC<IProps> = ({}: IProps) => {
  const { t } = useTranslation();
  const [isMortgageCalculatorModalOpen, setIsMortgageCalculatorModalOpen] = useState(false);

  function openMortgageCalculatorModal() {
    setIsMortgageCalculatorModalOpen(true);
  }

  function closeMortgageCalculatorModal() {
    setIsMortgageCalculatorModalOpen(false);
  }

  return (
    <>
      <StyledTransparentButton onClick={openMortgageCalculatorModal}>
        <FooterItem>
          <CalculatorIcon />
          <FooterItemText>{t("apartment:actions.mortgageCalculator")}</FooterItemText>
        </FooterItem>
      </StyledTransparentButton>

      <MortgageCalculatorModal show={isMortgageCalculatorModalOpen} onHide={closeMortgageCalculatorModal} />
    </>
  );
};
