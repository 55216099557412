import React from "react";
import { Page } from "../page/page";
import { Center } from "../center/center";
import { useTranslation } from "react-i18next";

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Page>
        <Center>
          <h2>404 Not found.</h2>
        </Center>
      </Page>
    </div>
  );
};
