import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { SectorRepoImpl } from "../../data/repo";
import { ISector } from "../../data/entities";

enum ESectorActionType {
  GET_SECTOR_DETAIL = "GET_SECTOR_DETAIL",
}

export interface ISectorReduxState {
  details: IAsyncData<ISector>;
}

const getDetail = (id: string): IAsyncReduxAction<ISector> => ({
  type: ESectorActionType.GET_SECTOR_DETAIL,
  payload: SectorRepoImpl.getSectorDetail(id),
});
const getDetailReducer = asyncItemReducerGenerator<ISector>(ESectorActionType.GET_SECTOR_DETAIL);

export const sectorRedux = {
  actions: {
    getDetail,
  },
  reducers: {
    details: getDetailReducer,
  },
};

export const SectorReducers = combineReducers<ISectorReduxState>({
  ...sectorRedux.reducers,
});
