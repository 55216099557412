import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardBackspace } from "react-icons/md";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { theme } from "../../theme/variables";
import { TransparentButton } from "./transparent_button";

const Wrapper = styled.div`
  position: fixed;
  left: 50px;
  top: 120px;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const StyledBackLink = styled(Link)`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.green};
  padding: 14px 30px 14px 20px;
  border-radius: 33px;

  p {
    font-size: 20px;
    margin: 0;
    margin-left: 2px;
  }

  @media screen and (min-width: 992px) {
    left: 20px;
    top: 120px;
  }
`;

const StyledBackButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.green};
  padding: 14px 30px 14px 20px;
  border-radius: 33px;

  p {
    font-size: 20px;
    margin: 0;
    margin-left: 2px;
  }

  @media screen and (min-width: 992px) {
    left: 20px;
    top: 120px;
  }
`;

interface IProps {
  href?: string;
  onClick?: () => void;
}

export const BackLink: React.FC<IProps> = ({ href, onClick }: IProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {href && (
        <StyledBackLink to={href}>
          <MdKeyboardBackspace size={24} />
          <p>Əvvəlki</p>
        </StyledBackLink>
      )}

      {onClick && (
        <StyledBackButton onClick={onClick}>
          <MdKeyboardBackspace size={24} />
          <p>Əvvəlki</p>
        </StyledBackButton>
      )}
    </Wrapper>
  );
};
