import * as React from "react";

export function SofaIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="38px" height="28px" viewBox="0 0 38 28" {...props}>
      <path
        d="M33,6.6 L31.8,6.6 C31.1487,6.602175 30.516225,6.81855 30,7.2156 L30,4.98 C30,2.2326 28.1154,0 25.8,0 L10.2,0 C7.8846,0 6,2.235 6,4.98 L6,7.2132 C5.483475,6.816975 4.851,6.6015 4.2,6.6 L3,6.6 C1.344,6.60195 0.00195,7.944 0,9.6 L0,20.4 C0.00195,22.056 1.344,23.39805 3,23.4 L4.8,23.4 L4.8,25.2 C4.8,25.53135 5.06865,25.8 5.4,25.8 L7.8,25.8 C8.13135,25.8 8.4,25.53135 8.4,25.2 L8.4,23.4 L27.6,23.4 L27.6,25.2 C27.6,25.53135 27.86865,25.8 28.2,25.8 L30.6,25.8 C30.93135,25.8 31.2,25.53135 31.2,25.2 L31.2,23.4 L33,23.4 C34.656,23.39805 35.99805,22.056 36,20.4 L36,9.6 C35.99805,7.944 34.656,6.60195 33,6.6 Z M7.2,4.98 C7.2,2.8944 8.5458,1.2 10.2,1.2 L25.8,1.2 C27.4542,1.2 28.8,2.8968 28.8,4.98 L28.8,15.6 L7.2,15.6 L7.2,4.98 Z M7.2,24.6 L6,24.6 L6,23.4 L7.2,23.4 L7.2,24.6 Z M30,24.6 L28.8,24.6 L28.8,23.4 L30,23.4 L30,24.6 Z M34.8,20.4 C34.8,21.394125 33.994125,22.2 33,22.2 L3,22.2 C2.005875,22.2 1.2,21.394125 1.2,20.4 L1.2,9.6 C1.2,8.605875 2.005875,7.8 3,7.8 L4.2,7.8 C5.194125,7.8 6,8.605875 6,9.6 L6,16.2 C6,16.53135 6.26865,16.8 6.6,16.8 L29.4,16.8 C29.73135,16.8 30,16.53135 30,16.2 L30,9.6 C30,8.605875 30.805875,7.8 31.8,7.8 L33,7.8 C33.994125,7.8 34.8,8.605875 34.8,9.6 L34.8,20.4 Z"
        id="Shape"
      ></path>
    </svg>
  );
}
