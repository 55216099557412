import { ApiClient, apiClient } from "../../../core/api_client";
import {
  IProject,
  projectFromJson,
  IProjectApartment,
  IPaginated,
  IProjectApartmentFilter,
  paginatedDataFromJson,
  projectApartmentFromJson,
  projectApartmentFilterToJson,
} from "./entities";
import { Failure } from "../../../core/failure";

interface IProjectRepo {
  getProjectDetail: () => Promise<IProject>;
  getProjectApartments: (filter: IProjectApartmentFilter) => Promise<IPaginated<IProjectApartment>>;
}

const ProjectRepoImplFactory = (apiClient: ApiClient): IProjectRepo => {
  const r: IProjectRepo = {
    getProjectDetail: async () => {
      try {
        const res = await apiClient.get(`/getProjectSectors`);
        return projectFromJson(res.data);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
    getProjectApartments: async (filter) => {
      try {
        const data = projectApartmentFilterToJson(filter);
        console.log(JSON.stringify(data));
        const res = await apiClient.post(`/searchApartmentsV2`, data);
        return paginatedDataFromJson(res.data, projectApartmentFromJson);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const ProjectRepoImpl = ProjectRepoImplFactory(apiClient);
