import React from "react";
import { BackImage } from "../../../../components/back_image/back_image";
import { Page } from "../../../../components/page/page";
import { BackLink } from "../../../../components/back_link";
import { useHistory } from "react-router-dom";
import { Container } from "../../../../components/container";
import { ApartmentCalculatorButton } from "../components/apartment_calculator_button";
import { MortgageCalculatorButton } from "../components/mortgage_calculator_button";
import styled from "styled-components";
import { PageTitle } from "../../../../components/page_title";
import { theme } from "../../../../../theme/variables";

const ContentContainer = styled.div`
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 991px) {
    flex-direction: row;

    > div:first-child {
      margin-right: 15px;
    }

    > div:last-child {
      margin-left: 15px;
    }
  }
`;

const ButtonsContainer = styled.div`
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;

const ExampleText = styled.p`
  color: ${theme.colors.yellow};
`;

const Bordered = styled.div`
  border: 2.5px dashed ${theme.colors.yellow};
  border-radius: 5px;
  padding: 10px 20px;
`;

const ExampleItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${theme.colors.yellow};
    margin: 0;
  }

  p:nth-child(2) {
    font-size: 22px;
    font-weight: 800;
  }
`;

interface IProps {}

export const AboutDiscountsPage: React.FC<IProps> = ({}: IProps) => {
  const history = useHistory();

  return (
    <div>
      <BackImage />
      <Page>
        <ContentContainer>
          <Container>
            <PageTitle>Əlavə endirim haqqında</PageTitle>
            <Row>
              <div>
                <p>Mənzilə öz vəsaiti hesabına sahib olmaq istəyən vətəndaş 10% endirim əldə edəcək.</p>

                <ExampleText>Nümunə:</ExampleText>

                <Bordered>
                  <ExampleItem>
                    <p>Mənzilin satış qiyməti:</p>
                    <p>50 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>Endirim məbləği 10%:</p>
                    <p>5 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>Mənzilin son qiyməti:</p>
                    <p>45 000 AZN</p>
                  </ExampleItem>
                </Bordered>
              </div>
              <div>
                <p>
                  Vətəndaş mənzili Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun müddəti 30 ilədək olan
                  güzəştli krediti hesabına, 10% ilkin ödənişlə, illik 4%-lə ala bilər. Mənzilə güzəştli ipoteka krediti
                  hesabına sahib olmaq istəyən vətəndaş 10%-dən daha çox ilkin ödənişi edərsə, hər artıq ödənilən
                  məbləğə görə 10% endirim əldə edəcək.
                </p>

                <ExampleText>Nümunə:</ExampleText>

                <Bordered>
                  <ExampleItem>
                    <p>Mənzilin satış qiyməti:</p>
                    <p>50 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>İlkin ödəniş 10% olduqda:</p>
                    <p>5 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>İlkin ödəniş 40% olduqda:</p>
                    <p>20 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>Artıq ödənilən məbləğ:</p>
                    <p>15 000 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>Endirim olunan məbləğ:</p>
                    <p>1 500 AZN</p>
                  </ExampleItem>
                  <ExampleItem>
                    <p>Mənzilin son qiyməti:</p>
                    <p>48 500 AZN</p>
                  </ExampleItem>
                </Bordered>
              </div>
            </Row>
          </Container>
        </ContentContainer>

        <ButtonsContainer>
          <ApartmentCalculatorButton />
          <div className="mr-5" />
          <MortgageCalculatorButton />
        </ButtonsContainer>
      </Page>

      <BackLink
        onClick={() => {
          history.goBack();
        }}
      />
    </div>
  );
};
