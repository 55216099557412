import { ApiClient, apiClient } from "../../../core/api_client";
import { IAbout, aboutFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface IAboutRepo {
  getList: () => Promise<IAbout[]>;
}

const AboutRepoImplFactory = (apiClient: ApiClient): IAboutRepo => {
  const r: IAboutRepo = {
    getList: async () => {
      try {
        const res = await apiClient.get(`/generalInfo`);
        return res.data.map(aboutFromJson);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const AboutRepoImpl = AboutRepoImplFactory(apiClient);
