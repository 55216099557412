import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { TextInput } from "../../../../components/text_input";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FormLabel = styled(Form.Label)`
  font-weight: 800;
`;

const FirstPaymentPercentCol = styled(Col)`
  margin-bottom: 10px;

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;

interface IProps {
  onSubmit: (values: IApartmentForm) => void;
  formRef?: React.RefObject<HTMLFormElement> | null;
}

export interface IApartmentForm {
  apartmentPrice: number;
  firstPaymentPercent: number;
  firstPaymentAmount: number;
  isPercentSelected: boolean;
}

const initialValues: IApartmentForm = {
  apartmentPrice: 0,
  firstPaymentPercent: 0,
  firstPaymentAmount: 0,
  isPercentSelected: true,
};

export function ApartmentForm({ onSubmit, formRef }: IProps) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={yup.object<IApartmentForm>({
        apartmentPrice: yup
          .number()
          .min(1, "Mənzilin qiyməti 0-dan böyük olmalıdır")
          .required("Mənzilin qiyməti 0-dan böyük olmalıdır"),
        firstPaymentPercent: yup
          .number()
          .typeError("İlkin ödəniş minimum 10% olmalıdır")
          .min(10, "İlkin ödəniş minimum 10% olmalıdır")
          .max(90, "İlkin ödəniş maksimum 90% olmalıdır")
          .required("İlkin ödəniş minimum 10% olmalıdır"),
        firstPaymentAmount: yup.number().required(),
        isPercentSelected: yup.boolean().required(),
      })}
    >
      {({ handleReset, handleSubmit, setFieldValue, values, errors, touched }) => {
        return (
          <form ref={formRef} onReset={handleReset} onSubmit={handleSubmit}>
            <TextInput type="number" name="apartmentPrice" label="Mənzilin satış qiyməti" append="AZN" />

            <FormLabel>İlkin ödəniş (minimum 10%)</FormLabel>
            <Row>
              <FirstPaymentPercentCol xs={12} sm={6} md={12} lg={6}>
                <TextInput
                  type="number"
                  name="firstPaymentPercent"
                  append="%"
                  onChange={(e: any) => {
                    const newFirstPaymentPercent = e.target.value;
                    const newFirstPaymentAmount = (values.apartmentPrice * newFirstPaymentPercent * 0.01).toFixed(2);

                    setFieldValue("isPercentSelected", true);
                    setFieldValue("firstPaymentAmount", newFirstPaymentAmount);
                  }}
                  showErrors={false}
                />
              </FirstPaymentPercentCol>
              <Col xs={12} sm={6} md={12} lg={6}>
                <TextInput
                  type="number"
                  name="firstPaymentAmount"
                  append="AZN"
                  onChange={async (e) => {
                    const newFirstPaymentAmount = e.target.value;
                    const newFirstPaymentPercent = ((newFirstPaymentAmount / values.apartmentPrice) * 100).toFixed(2);

                    setFieldValue("isPercentSelected", false);
                    setFieldValue("firstPaymentPercent", newFirstPaymentPercent);
                  }}
                  showErrors={false}
                />
              </Col>
            </Row>
            <Form.Text className="text-danger mb-3">
              {touched.firstPaymentPercent && errors.firstPaymentPercent && errors.firstPaymentPercent}
            </Form.Text>
          </form>
        );
      }}
    </Formik>
  );
}
