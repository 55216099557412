import React, { useState } from "react";
import classes from "./layout.module.scss";
import { NavItem } from "../nav_item/nav_item";
import logo from "../../../assets/images/logo-white.svg";
import { Center } from "../center/center";
import { ROUTES } from "../../routes";
// import { LangSelector } from "../lang_selector/lang_selector";
import Container from "react-bootstrap/Container";
import { NavDropdown } from "../nav_dropdown/nav_dropdown";
import { TransparentButton } from "../transparent_button";
import styled from "styled-components";
import { rgba } from "polished";
import { useDocumentScrollThrottled } from "./utils";
import { SideMenu } from "../side_menu";
import { MdMenu } from "react-icons/md";
import { ExtendedLink } from "../../i18n";
import { useTranslation } from "react-i18next";
import { theme } from "../../../theme/variables";

const Header = styled.div<{ shouldHide: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: linear-gradient(
    -90deg,
    ${rgba("#212c3e", 0.69)} 0%,
    ${rgba("#34425b", 0.55)} 90%,
    ${rgba("#34425b", 0.05)} 100%
  );
  transform: translateY(${(props) => (props.shouldHide ? "-54px" : "0")});
  transition: 0.2s transform ease;
  height: 54px;

  @media screen and (min-width: 992px) {
    height: 100px;
    transform: translateY(${(props) => (props.shouldHide ? "-100px" : "0")});
    transition: 0.3s transform ease;
  }
`;

const MenuToggleButton = styled(TransparentButton)`
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const RegisterLink = styled.a`
  color: white;
  text-decoration: none;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-weight: 500;
  transition: 0.2s color ease-in;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover,
  &:focus,
  &.isActive {
    color: ${theme.colors.blue};
    text-decoration: none;
  }
`;

interface IProps {
  children: React.ReactNode;
}

const TIMEOUT_DELAY = 0;
const MINIMUM_SCROLL = 50;

export const Layout: React.FC<IProps> = ({ children }: IProps) => {
  const { t } = useTranslation();

  const [shouldHideHeader, setShouldHideHeader] = useState<boolean>(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);

  function openSideMenu() {
    setIsSideMenuOpen(true);
  }
  function closeSideMenu() {
    setIsSideMenuOpen(false);
  }

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div className={classes.root}>
      <Header shouldHide={shouldHideHeader}>
        <Container fluid>
          <div className={classes.header}>
            <div className={classes.left}>
              <ExtendedLink to={ROUTES.HOME} className={classes.logo_link}>
                <div className={classes.logo}>
                  <Center>
                    <img src={logo} alt="Logo" />
                  </Center>
                </div>
                <div className={classes.logo_text}>
                  <Center>
                    <div>
                      <h3 className={classes.title}>HÖVSAN</h3>
                      <h5 className={classes.description}>YAŞAYIŞ KOMPLEKSİ</h5>
                    </div>
                  </Center>
                </div>
              </ExtendedLink>
            </div>
            <nav className={classes.nav}>
              <NavItem title={t("layout:home")} to={ROUTES.HOME} exact />
              <NavDropdown title={t("layout:about")}>
                <NavItem title={t("layout:generalInfo")} to={ROUTES.ABOUT} />
                <NavItem title={t("layout:benefits")} to={ROUTES.BENEFITS} />
              </NavDropdown>
              <NavItem title={t("layout:apartments")} to={ROUTES.project} />
              <NavDropdown title={t("layout:conditions")}>
                <RegisterLink
                  href="https://www.e-gov.az/az/services/info/3709/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("layout:registration")}
                </RegisterLink>
                <NavItem title={t("layout:paymentMethods")} to={ROUTES.CONDITIONS} />
              </NavDropdown>
              <NavItem title={t("layout:gallery")} to={ROUTES.GALLERY} />
              <NavItem title={t("layout:contact")} to={ROUTES.CONTACT} />
              {/* <LangSelector /> */}
            </nav>

            <MenuToggleButton onClick={openSideMenu}>
              <MdMenu size={30} />
            </MenuToggleButton>
          </div>
        </Container>
      </Header>
      <SideMenu isOpen={isSideMenuOpen} onClose={closeSideMenu} />
      <main className={classes.main}>{children}</main>
    </div>
  );
};
