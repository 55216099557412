import { IMultiLang, multiLangFromJson } from "../../../core/models";

export interface IContact {
  id: string;
  name: IMultiLang;
  address: IMultiLang;
  url: string;
}

export const contactFromJson = (json: any): IContact => {
  const e: IContact = {
    id: `${json.id}`,
    name: multiLangFromJson(json, "name"),
    address: multiLangFromJson(json, "address"),
    url: json.url,
  };

  return e;
};
