import { ApiClient, apiClient } from "../../../core/api_client";
import { IContact, contactFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface IContactRepo {
  getList: () => Promise<IContact[]>;
}

const ContactRepoImplFactory = (apiClient: ApiClient): IContactRepo => {
  const r: IContactRepo = {
    getList: async () => {
      try {
        const res = await apiClient.get(`/contacts`);
        return res.data.map(contactFromJson);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const ContactRepoImpl = ContactRepoImplFactory(apiClient);
