import { ApiClient, apiClient } from "../../../core/api_client";
import { ISector, sectorFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface ISectorRepo {
  getSectorDetail: (id: string) => Promise<ISector>;
}

const SectorRepoImplFactory = (apiClient: ApiClient): ISectorRepo => {
  const r: ISectorRepo = {
    getSectorDetail: async (id) => {
      try {
        const res = await apiClient.get(`/getSectorFloors/${id}`);
        return sectorFromJson(res.data);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const SectorRepoImpl = SectorRepoImplFactory(apiClient);
