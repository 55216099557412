export const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  BENEFITS: "/benefits",
  CONTACT: "/contact",
  GALLERY: "/gallery",
  CONDITIONS: "/conditions",
  project: "/project",
  sector: "/sector",
  floor: "/floor",
  apartment: "/apartment",
  aboutDiscounts: "/about-discounts",
  aboutRepair: "/about-repair",
};
