import { ApiClient, apiClient } from "../../../core/api_client";
import { IBenefit, benefitFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface IBenefitRepo {
  getList: () => Promise<IBenefit[]>;
}

const BenefitRepoImplFactory = (apiClient: ApiClient): IBenefitRepo => {
  const r: IBenefitRepo = {
    getList: async () => {
      try {
        const res = await apiClient.get(`/benefits`);
        return res.data.map(benefitFromJson);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const BenefitRepoImpl = BenefitRepoImplFactory(apiClient);
