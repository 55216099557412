import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { GalleryRepoImpl } from "../../data/repo";
import { IGallery } from "../../data/entities";

enum EGalleryActionType {
  GET_GALLERY_LIST = "GET_GALLERY_LIST",
}

export interface IGalleryReduxState {
  list: IAsyncData<IGallery[]>;
}

const getList = (): IAsyncReduxAction<IGallery[]> => ({
  type: EGalleryActionType.GET_GALLERY_LIST,
  payload: GalleryRepoImpl.getList(),
});
const getListReducer = asyncItemReducerGenerator<IGallery[]>(EGalleryActionType.GET_GALLERY_LIST);

export const galleryRedux = {
  actions: {
    getList,
  },
  reducers: {
    list: getListReducer,
  },
};

export const GalleryReducers = combineReducers<IGalleryReduxState>({
  ...galleryRedux.reducers,
});
