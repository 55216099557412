import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: inherit;
`;

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
}

export const TransparentButton: React.FC<IProps> = ({ children, ref, ...props }: IProps) => {
  return <Button {...props}>{children}</Button>;
};
