import { ApiClient, apiClient } from "../../../core/api_client";
import { ICondition, conditionFromJson } from "./entities";
import { Failure } from "../../../core/failure";

interface IConditionRepo {
  getDetails: () => Promise<ICondition>;
}

const ConditionRepoImplFactory = (apiClient: ApiClient): IConditionRepo => {
  const r: IConditionRepo = {
    getDetails: async () => {
      try {
        const res = await apiClient.get(`/conditions`);
        return conditionFromJson(res.data);
      } catch (error) {
        const failure: Failure = {
          message: error.message ?? "Unhandled failure",
        };
        throw failure;
      }
    },
  };

  return r;
};

export const ConditionRepoImpl = ConditionRepoImplFactory(apiClient);
