import { IImage, generateImage } from "../../../core/image";

import { IMultiLang, multiLangFromJson } from "../../../core/models";

export interface IHomeSlider {
  id: string;
  image: IImage;
  title: IMultiLang;
  description: IMultiLang;
}

export const homeSliderFromJson = (json: any): IHomeSlider => {
  const e: IHomeSlider = {
    id: `${json.id}`,
    title: multiLangFromJson(json, "title"),
    description: multiLangFromJson(json, "description"),
    image: generateImage(json),
  };

  return e;
};
