import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { ConditionRepoImpl } from "../../data/repo";
import { ICondition } from "../../data/entities";

enum EConditionActionType {
  GET_CONDITION_DETAILS = "GET_CONDITION_DETAILS",
}

export interface IConditionReduxState {
  details: IAsyncData<ICondition>;
}

const getDetails = (): IAsyncReduxAction<ICondition> => ({
  type: EConditionActionType.GET_CONDITION_DETAILS,
  payload: ConditionRepoImpl.getDetails(),
});
const getDetailsReducer = asyncItemReducerGenerator<ICondition>(EConditionActionType.GET_CONDITION_DETAILS);

export const conditionRedux = {
  actions: {
    getDetails,
  },
  reducers: {
    details: getDetailsReducer,
  },
};

export const ConditionReducers = combineReducers<IConditionReduxState>({
  ...conditionRedux.reducers,
});
