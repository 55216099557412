import { IMultiLang, multiLangFromJson } from "../../../core/models";
import { IImage, generateImage } from "../../../core/image";

interface IConditionImage {
  id: string;
  url: string;
  image: IImage;
}

export interface ICondition {
  id: string;
  description: IMultiLang;
  images: IConditionImage[];
}

export const conditionFromJson = (json: any): ICondition => {
  const e: ICondition = {
    id: `${json[0].id}`,
    description: multiLangFromJson(json[0], "description"),
    images: json.files?.map((file: any) => ({
      id: file.id,
      url: file.link,
      image: generateImage(file),
    })),
  };

  return e;
};
