import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { FloorRepoImpl } from "../../data/repo";
import { IFloor } from "../../data/entities";

enum EFloorActionType {
  GET_FLOOR_DETAIL = "GET_FLOOR_DETAIL",
}

export interface IFloorReduxState {
  details: IAsyncData<IFloor>;
}

const getDetail = (apartmentId: string, floorId: string): IAsyncReduxAction<IFloor> => ({
  type: EFloorActionType.GET_FLOOR_DETAIL,
  payload: FloorRepoImpl.getFloorDetail(apartmentId, floorId),
});
const getDetailReducer = asyncItemReducerGenerator<IFloor>(EFloorActionType.GET_FLOOR_DETAIL);

export const floorRedux = {
  actions: {
    getDetail,
  },
  reducers: {
    details: getDetailReducer,
  },
};

export const FloorReducers = combineReducers<IFloorReduxState>({
  ...floorRedux.reducers,
});
