import React, { useState } from "react";
import { StyledTransparentButton, FooterItem, FooterItemText } from "./apartment_actions";
import { CalculatorIcon } from "../images/calculator_icon";
import { useTranslation } from "react-i18next";
import { ApartmentCalculatorModal } from "./apartment_calculator_modal";

interface IProps {}

export const ApartmentCalculatorButton: React.FC<IProps> = ({}: IProps) => {
  const { t } = useTranslation();
  const [isApartmentCalculatorModalOpen, setIsApartmentCalculatorModalOpen] = useState(false);

  function openApartmentCalculatorModal() {
    setIsApartmentCalculatorModalOpen(true);
  }

  function closeApartmentCalculatorModal() {
    setIsApartmentCalculatorModalOpen(false);
  }

  return (
    <>
      <StyledTransparentButton onClick={openApartmentCalculatorModal}>
        <FooterItem>
          <CalculatorIcon />
          <FooterItemText>{t("apartment:actions.apartmentCalculator")}</FooterItemText>
        </FooterItem>
      </StyledTransparentButton>

      <ApartmentCalculatorModal show={isApartmentCalculatorModalOpen} onHide={closeApartmentCalculatorModal} />
    </>
  );
};
