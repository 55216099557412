import React from "react";
import { Formik, Form as FomrikForm } from "formik";
import * as yup from "yup";
import { TextInput } from "../../../../components/text_input";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import styled from "styled-components";

const SubmitButton = styled.button`
  padding: 10px 31px;
  background-color: #3dc8f1;
  color: white;
  border-radius: 35px;
  font-weight: 800;
  border: none;
`;

const SubmitButtonWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
`;

export interface IMortgageForm {
  monthlySalary: number;
  familyMembersCount: number;
  otherCommitments: number;
  period: number;
  initialFeePercent: number;
  annualLoanInterest: number;
}

const initialFormStateWithDiscount: IMortgageForm = {
  monthlySalary: 0,
  familyMembersCount: 0,
  otherCommitments: 30,
  period: 30,
  initialFeePercent: 10,
  annualLoanInterest: 4,
};

const initialFormStateWithoutDiscount: IMortgageForm = {
  monthlySalary: 0,
  familyMembersCount: 0,
  otherCommitments: 30,
  period: 25,
  initialFeePercent: 15,
  annualLoanInterest: 8,
};

interface IProps {
  onSubmit: (values: IMortgageForm) => void;
  isWithDiscount: boolean;
  formRef?: React.RefObject<HTMLFormElement> | null;
}

const Input = ({
  info,
  label,
  ...rest
}: {
  info: string;
  label?: string;
  name: string;
  append: string;
  type?: string;
}) => {
  const InputElement = (
    <FormGroup className="w-100 mb-0">
      <Row>
        <Col xs={12} sm={5}>
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col xs={12} sm={7}>
          <TextInput {...rest} />
        </Col>
      </Row>
    </FormGroup>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{info}</Tooltip>}>
        {InputElement}
      </OverlayTrigger>
    </ButtonToolbar>
  );
};

const NUMBER_ERROR_MSG = "Tam ədəd olmalıdır";

export function MortgageForm({ onSubmit, isWithDiscount }: IProps) {
  const maxLoanPeriod = isWithDiscount ? 30 : 25;
  const minInitialFeePercent = isWithDiscount ? 10 : 15;
  const minAnnualLoanInterest = isWithDiscount ? 1 : 5;
  const maxAnnualLoanInterest = isWithDiscount ? 4 : 100;

  return (
    <Formik
      initialValues={isWithDiscount ? initialFormStateWithDiscount : initialFormStateWithoutDiscount}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={yup.object<IMortgageForm>({
        monthlySalary: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(1, "Aylıq gəlir 0 AZN-dən böyük olmalıdır")
          .required(NUMBER_ERROR_MSG),
        familyMembersCount: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(1, "Ailə üzvlərinin sayı 0-dan böyük olmalıdır")
          .required(NUMBER_ERROR_MSG),
        otherCommitments: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(30, "Digər öhdəliklər 30 AZN-dən böyük olmalıdır")
          .required(NUMBER_ERROR_MSG),
        period: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(3, "Kreditin müddəti 3 ildən böyük olmalıdır")
          .max(maxLoanPeriod, `Kreditin müddəti ${maxLoanPeriod} ildən böyük ola bilməz`)
          .required(NUMBER_ERROR_MSG),
        initialFeePercent: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(minInitialFeePercent, `İlkin ödəniş ${minInitialFeePercent}%-dən böyük olmalıdır`)
          .required(NUMBER_ERROR_MSG),
        annualLoanInterest: yup
          .number()
          .integer(NUMBER_ERROR_MSG)
          .min(minAnnualLoanInterest, `Kredit faizi ${minAnnualLoanInterest - 1}%-dən çox olmalıdır`)
          .max(maxAnnualLoanInterest, `Kredit faizi ${maxAnnualLoanInterest}%-dən çox olmamalıdır`)
          .required(NUMBER_ERROR_MSG),
      })}
    >
      {() => {
        return (
          <FomrikForm>
            <Input
              info="Aylıq ümumi rəsmi gəlir nəzərdə tutulur. Bura maaşınızla bərabər, varsa digər 
              gəlirləriniz də aiddir. (Birgə borcalanlar varsa onların gəlirləri də daxildir)"
              label="Aylıq gəlir:"
              name="monthlySalary"
              append="AZN"
              type="number"
            />

            <Input
              info="Borcalan və borcalanla birgə yaşayan (onun himayəsində olan) şəxslər"
              label="Ailə üzvlərinin sayı:"
              name="familyMembersCount"
              append="Nəfər"
              type="number"
            />

            <Input
              info="İpoteka predmetinin və Borcalanın həyat sığortası üzrə ödənişlər, borcalanın 
              mülkiyyətində olan / olacaq yaşayış sahəsi ilə bağlı xərclər (texniki xidmət, kommunal xidmətlər), 
              digər öhdəliklər üzrə ödənişlər (kredit borcu, alimentlər və s.)"
              label="Digər öhdəliklər:"
              name="otherCommitments"
              append="AZN"
              type="number"
            />

            <Input
              info={`Kreditin verilmə müddətidir. İpoteka kreditinin verilmə şərtlərinə əsasən müddət 3 il ilə
              ${maxLoanPeriod} il arasında ola bilər.`}
              label="Kreditin müddəti:"
              name="period"
              append="İl"
              type="number"
            />

            <Input
              info="Almaq istədiyiniz evin dəyərinin əvvəlcədən ödəyəcəyiniz hissəsi. İpoteka kreditinin verilmə
              şərtlərinə əsasən bu ən azı 10%-ni təşkil etməlidir. Yəni, almaq istədiyiniz evin minimum 10%-ni
              əvvəlcədən ödəməlisiniz. Qalan 90%-i isə ipoteka krediti şəklində ödəyəcəksiniz (maksimum 100000AZN
              olmaq şərtilə)."
              label="İlkin ödəniş (minimal):"
              name="initialFeePercent"
              append="%"
              type="number"
            />

            <Input
              info={`İpoteka krediti üçün təyin edilən illik faiz nəzərdə tutulur. İpoteka kreditinin verilmə şərtlərinə
              əsasən faiz dərəcəsi ${
                isWithDiscount
                  ? `${maxAnnualLoanInterest}%-dən çox ola bilməz`
                  : `${minAnnualLoanInterest - 1}%-dən çox olmalıdır`
              }`}
              label="Kredit faizi (illik):"
              name="annualLoanInterest"
              append="%"
              type="number"
            />

            <SubmitButtonWrapper>
              <SubmitButton>Hesabla</SubmitButton>
            </SubmitButtonWrapper>
          </FomrikForm>
        );
      }}
    </Formik>
  );
}
