import * as Yup from "yup";

export interface ICoordinate {
  x: number;
  y: number;
}

export const coordinateValidation = Yup.object<ICoordinate>({
  x: Yup.number().required(),
  y: Yup.number().required(),
});

export interface IShape {
  id: string;
  points: ICoordinate[];
  closed: boolean;
}

export function shapeFromJson(json: any, id: string): IShape {
  const parsed = JSON.parse(json.coordinates);
  return {
    id,
    points: parsed.points,
    closed: true,
  };
}

export const shapeValidation = Yup.object<IShape>({
  points: Yup.array<ICoordinate>(coordinateValidation).required(),
  closed: Yup.boolean().required(),
  id: Yup.string().required(),
});
