import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { BenefitRepoImpl } from "../../data/repo";
import { IBenefit } from "../../data/entities";

enum EBenefitActionType {
  GET_BENEFIT_LIST = "GET_BENEFIT_LIST",
}

export interface IBenefitReduxState {
  list: IAsyncData<IBenefit[]>;
}

const getList = (): IAsyncReduxAction<IBenefit[]> => ({
  type: EBenefitActionType.GET_BENEFIT_LIST,
  payload: BenefitRepoImpl.getList(),
});
const getListReducer = asyncItemReducerGenerator<IBenefit[]>(EBenefitActionType.GET_BENEFIT_LIST);

export const benefitRedux = {
  actions: {
    getList,
  },
  reducers: {
    list: getListReducer,
  },
};

export const BenefitReducers = combineReducers<IBenefitReduxState>({
  ...benefitRedux.reducers,
});
