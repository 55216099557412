import { combineReducers } from "redux";
import { IAsyncData } from "../../../../core/models";
import { IAsyncReduxAction, asyncItemReducerGenerator } from "../../../../core/redux";
import { ContactRepoImpl } from "../../data/repo";
import { IContact } from "../../data/entities";

enum EContactActionType {
  GET_CONTACT_LIST = "GET_CONTACT_LIST",
}

export interface IContactReduxState {
  list: IAsyncData<IContact[]>;
}

const getList = (): IAsyncReduxAction<IContact[]> => ({
  type: EContactActionType.GET_CONTACT_LIST,
  payload: ContactRepoImpl.getList(),
});
const getListReducer = asyncItemReducerGenerator<IContact[]>(EContactActionType.GET_CONTACT_LIST);

export const contactRedux = {
  actions: {
    getList,
  },
  reducers: {
    list: getListReducer,
  },
};

export const ContactReducers = combineReducers<IContactReduxState>({
  ...contactRedux.reducers,
});
