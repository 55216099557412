import { IImage, generateImage } from "../../../core/image";

export interface IGallery {
  id: string;
  image: IImage;
}

export const galleryFromJson = (json: any): IGallery => {
  const e: IGallery = {
    id: `${json.id}`,
    image: generateImage(json),
  };

  return e;
};
