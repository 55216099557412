import React from "react";
import classnames from "classnames";
import classes from "./nav_dropdown.module.scss";

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string;
  children: React.ReactNode;
}

export const NavDropdown: React.FC<IProps> = ({ title, children, className, ...rest }: IProps) => {
  return (
    <div className={classnames(classes.nav_dropdown, className)} {...rest}>
      <div className={classes.toggle}>{title}</div>
      <div className={classes.dropdown}>
        <div className={classes.dropdown_inner}>{children}</div>
      </div>
    </div>
  );
};
