import React, { useState, useRef } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { ModalHeader, ModalContent } from "./interior_design_modal";
import styled from "styled-components";
import { ApartmentForm, IApartmentForm } from "./apartment_calculator_form";
import { dispatchSubmitEvent, dispatchResetEvent } from "../../../../core/forms";
import { useTranslation } from "react-i18next";

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const ResetButton = styled.button`
  margin-right: 20px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #424650;
`;

const SubmitButton = styled.button`
  padding: 10px 31px;
  background-color: #3dc8f1;
  color: white;
  border-radius: 35px;
  font-weight: 800;
  border: none;
`;

const FormLabel = styled(Form.Label)`
  font-weight: 800;
`;

interface IProps extends ModalProps {}

interface IFormResult {
  price: number;
  discount: number;
  loanAmount: number;
}

const initialFormResult: IFormResult = {
  price: 0,
  discount: 0,
  loanAmount: 0,
};

function calculateDiscount(params: IApartmentForm): number {
  const thresholdAmount = params.apartmentPrice * 0.9;
  const requiredInitalPaymentAmount = params.apartmentPrice * 0.1;
  const initalPaymentAmount = params.isPercentSelected
    ? params.apartmentPrice * params.firstPaymentPercent * 0.01
    : params.firstPaymentAmount;
  const discountAmount = (initalPaymentAmount - requiredInitalPaymentAmount) * 0.1;

  if (initalPaymentAmount >= thresholdAmount) {
    return params.apartmentPrice * 0.1;
  }

  return discountAmount;
}

function calculateTotalPriceAfterDiscount(params: IApartmentForm): number {
  return params.apartmentPrice - calculateDiscount(params);
}

function calculateTotalLoanAmount(params: IApartmentForm): number {
  return calculateTotalPriceAfterDiscount(params) - params.apartmentPrice * params.firstPaymentPercent * 0.01;
}

export const ApartmentCalculatorModal: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [result, setResult] = useState<IFormResult>(initialFormResult);
  const formRef = useRef<HTMLFormElement>(null);

  function submitForm() {
    dispatchSubmitEvent(formRef);
  }

  function reset() {
    dispatchResetEvent(formRef);
    setResult(initialFormResult);
  }

  function calculateResult(form: IApartmentForm) {
    setResult({
      price: calculateTotalPriceAfterDiscount(form),
      discount: calculateDiscount(form),
      loanAmount: calculateTotalLoanAmount(form),
    });
  }

  return (
    <Modal dialogClassName="modal-90w" {...props}>
      <ModalHeader closeButton>
        <Modal.Title>«Güzəştli mənzil» kalkulyatoru</Modal.Title>
      </ModalHeader>
      <ModalContent>
        <Row>
          <Col sm={12} md={4}>
            <ApartmentForm onSubmit={calculateResult} formRef={formRef} />
          </Col>
          <Col sm={12} md={8}>
            <Row>
              <Col sm={12} md={{ offset: 1, span: 7 }}>
                <Form.Group controlId="formBasicEmail">
                  <FormLabel>Əlavə endirimdən sonra mənzilin satış qiyməti</FormLabel>
                  <p>{result.price.toFixed(2)} AZN</p>
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group controlId="formBasicEmail">
                  <FormLabel>Əlavə endirim məbləği</FormLabel>
                  <p>{result.discount.toFixed(2)} AZN</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={{ offset: 1, span: 7 }}>
                <Form.Group controlId="formBasicEmail">
                  <FormLabel>Kredit məbləği</FormLabel>
                  <p>{result.loanAmount.toFixed(2)} AZN</p>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={{ offset: 1 }}>
                <Alert variant="warning">
                  <p>
                    Mənzilə güzəştli ipoteka krediti hesabına sahib olmaq istəyən vətəndaş mənzilin satış qiymətinin
                    10%-dən daha çox ilkin ödəniş edərsə, hər artıq ödənilən məbləğə görə 10% endirim olunacaq.
                  </p>
                  <p>
                    Mənzilə öz vəsaiti hesabına sahib olmaq istəyən vətəndaş mənzilin dəyərinin 10% həcmində endirim
                    əldə edəcək.
                  </p>
                </Alert>
              </Col>
            </Row>

            <ActionRow>
              <ResetButton onClick={reset} type="button">
                Nəticəni sıfırla
              </ResetButton>
              <SubmitButton onClick={submitForm}>Hesabla</SubmitButton>
            </ActionRow>
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
};
