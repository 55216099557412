import React from "react";
import classes from "./page.module.scss";

interface IProps {
  children: React.ReactNode;
}

export const Page: React.FC<IProps> = ({ children }: IProps) => {
  return <div className={classes.page}>{children}</div>;
};
