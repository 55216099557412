export function dispatchSubmitEvent(formRef: React.RefObject<HTMLFormElement> | null) {
  if (formRef?.current) {
    formRef.current.dispatchEvent(new Event("submit"));
  }
}

export function dispatchResetEvent(formRef: React.RefObject<HTMLFormElement> | null) {
  if (formRef?.current) {
    formRef.current.dispatchEvent(new Event("reset"));
  }
}
