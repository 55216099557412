import React from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ModalHeader = styled(Modal.Header)`
  background-color: #97b997;
  border: none;
  display: flex;
  align-items: center;
`;

const ModalContent = styled(Modal.Body)`
  color: #424650;
  padding: 0;
  height: 80vh;
`;

interface IProps extends ModalProps {}

export const RepairTypeVideoModal: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal dialogClassName="modal-90w" {...props}>
      <ModalHeader closeButton>
        <Modal.Title>Təmir</Modal.Title>
      </ModalHeader>
      <ModalContent>
        <iframe
          title="Youtube video about repair"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/TtMJiAfTRxY"
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </ModalContent>
    </Modal>
  );
};
