import React, { useState } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ModalHeader, ModalContent } from "./interior_design_modal";
import styled from "styled-components";
import { MortgageForm, IMortgageForm } from "./mortgage_calculator_form";
import { useTranslation } from "react-i18next";

const RadioLabel = styled.label`
  display: flex;
  align-items: center;

  input {
    margin-bottom: 5px;
    margin-right: 5px;
  }
`;

const FormGroup = styled(Form.Group)`
  flex: 1;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
`;

const ResultTitle = styled.p`
  margin: 0;
  font-weight: 800;
`;

const ResultItem = styled.p`
  margin-bottom: 35px;
`;

function calculateFormResult({
  monthlySalary,
  familyMembersCount,
  otherCommitments,
  period,
  initialFeePercent,
  annualLoanInterest,
}: IMortgageForm): IFormResult {
  const $c12 = 155;
  const $c4 = parseFloat(monthlySalary.toString());
  let taxes = $c4 <= 2500 ? ($c4 - $c12) * 0.14 : 350 + ($c4 - 2500) * 0.25;
  taxes += $c4 * 0.03;
  const $c11h = taxes;
  // const $c11 = Math.round($c11h);
  const $c5 = parseFloat(familyMembersCount.toString());
  const $c13 = $c5 * $c12;
  const $c6 = parseFloat(otherCommitments.toString());
  const $c14h = $c11h + $c13 + $c6;
  // const $c14 = Math.round($c14h);
  const $c9 = parseFloat(annualLoanInterest.toString()) * 0.01;
  const $g6 = 100000;
  const $c7 = period;
  const $f8 = (($c9 / 12) * $g6) / (1 - 1 / Math.pow(1 + $c9 / 12, $c7 * 12));
  const $f18 = $c4 - $c14h > $c4 * 0.7 ? $c4 * 0.7 : $c4 - $c14h;
  const $c18 = $f18 < 0 ? 0 : $f18 <= $f8 ? $f18 : $f8;
  const $c8 = parseFloat(initialFeePercent.toString()) * 0.01;
  const $c15 = ($c18 * (1 - Math.pow(1 + $c9 / 12, -$c7 * 12))) / ($c9 / 12) / (1 - $c8);
  const $c15h = Math.round($c15);
  const $c16 = ($c18 * (1 - Math.pow(1 + $c9 / 12, -$c7 * 12))) / ($c9 / 12);
  const $c16h = Math.round($c16);
  const $c17 = $c15 - $c16;
  const $c17h = Math.round($c17);
  const $c18h = Math.round($c18);
  // const $c19 = Math.round($c18 * $c7 * 12);

  return {
    housePrice: $c15h,
    maxLoanAmount: $c16h,
    firstPayment: $c17h,
    monthlyPayment: $c18h,
  };
}

interface IProps extends ModalProps {}

interface IFormResult {
  housePrice: number;
  maxLoanAmount: number;
  firstPayment: number;
  monthlyPayment: number;
}

const initialFormResult: IFormResult = {
  housePrice: 0,
  maxLoanAmount: 0,
  firstPayment: 0,
  monthlyPayment: 0,
};

export const MortgageCalculatorModal: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  const [withDiscount, setWithDiscount] = useState<boolean>(false);
  const [result, setResult] = useState<IFormResult>(initialFormResult);

  function calculateResult(form: IMortgageForm) {
    setResult(calculateFormResult(form));
  }

  return (
    <Modal dialogClassName="modal-90w" {...props}>
      <ModalHeader closeButton>
        <Modal.Title>İpoteka kalkulyatoru</Modal.Title>
      </ModalHeader>
      <ModalContent>
        <Row>
          <Col md={12} lg={6}>
            <FormGroup>
              <Row>
                <Col xs={12} sm={6}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      checked={!withDiscount}
                      onChange={() => {
                        setWithDiscount(false);
                      }}
                    />
                    Ümumi ipoteka kreditləri
                  </RadioLabel>
                </Col>
                <Col xs={12} sm={6}>
                  <RadioLabel>
                    <input
                      type="radio"
                      name="type"
                      checked={withDiscount}
                      onChange={() => {
                        setWithDiscount(true);
                      }}
                    />
                    Güzəştli ipoteka kreditləri
                  </RadioLabel>
                </Col>
              </Row>
            </FormGroup>

            <MortgageForm onSubmit={calculateResult} isWithDiscount={withDiscount} />
          </Col>

          <Col md={12} lg={6}>
            <ResultWrapper>
              <div>
                <ResultTitle>Alınan evin ehtimal olunan qiyməti</ResultTitle>
                <ResultItem>{result.housePrice} AZN</ResultItem>

                <ResultTitle>Kredit maksimal məbləği</ResultTitle>
                <ResultItem>{result.maxLoanAmount} AZN</ResultItem>

                <ResultTitle>İlkin ödənişə minimal tələb</ResultTitle>
                <ResultItem>{result.firstPayment} AZN</ResultItem>

                <ResultTitle>Aylıq ödəniş (annuitet)</ResultTitle>
                <ResultItem>{result.monthlyPayment} AZN</ResultItem>
              </div>
            </ResultWrapper>
          </Col>
        </Row>
      </ModalContent>
    </Modal>
  );
};
