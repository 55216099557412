import React from "react";
import styled from "styled-components";
import { PlanIcon } from "../images/plan_icon";
import { TransparentButton } from "../../../../components/transparent_button";
import { SofaIcon } from "../images/sofa_icon";
import { PaintIcon } from "../images/paint_icon";
import { CalculatorIcon } from "../images/calculator_icon";
import { IAsyncData } from "../../../../core/models";
import { IApartment } from "../../data/entities";
import { AiOutlinePercentage } from "react-icons/ai";
import { theme } from "../../../../../theme/variables";
import { useTranslation } from "react-i18next";
import { ApartmentCalculatorButton } from "./apartment_calculator_button";
import { MortgageCalculatorButton } from "./mortgage_calculator_button";

const OptionsRow = styled.div`
  margin-top: auto;
`;

export const FooterItem = styled.div`
  text-align: center;
  fill: white;

  &:hover {
    fill: ${theme.colors.blue};
    color: ${theme.colors.blue};
  }

  @media (max-width: 1100px) {
    text-align: left;
  }

  @media (max-width: 550px) {
    text-align: center;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  padding: 0 20px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FooterItemText = styled.span`
  margin-left: 10px;
`;

export const StyledTransparentButton = styled(TransparentButton)`
  justify-self: center;
`;

export const ApartmentPlanLink = styled.a`
  justify-self: center;
`;

interface IProps {
  apartmentDetailBranch: IAsyncData<IApartment>;
  openInteriorDesignModal: () => any;
  openAboutRepairModal: () => any;
}

export const ApartmentActions: React.FC<IProps> = ({
  apartmentDetailBranch,
  openInteriorDesignModal,
  openAboutRepairModal,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <OptionsRow>
      <Grid>
        <ApartmentPlanLink target="_blank" rel="noopener noreferrer" href={apartmentDetailBranch.data?.fileUrl}>
          <FooterItem>
            <PlanIcon />
            <FooterItemText>{t("apartment:actions.apartmentPlan")}</FooterItemText>
          </FooterItem>
        </ApartmentPlanLink>
        <StyledTransparentButton onClick={openInteriorDesignModal}>
          <FooterItem>
            <SofaIcon />
            <FooterItemText>{t("apartment:actions.interiorExample")}</FooterItemText>
          </FooterItem>
        </StyledTransparentButton>
        <StyledTransparentButton onClick={openAboutRepairModal}>
          <FooterItem>
            <PaintIcon />
            <FooterItemText>{t("apartment:actions.aboutRepair")}</FooterItemText>
          </FooterItem>
        </StyledTransparentButton>
        <ApartmentCalculatorButton />
        <MortgageCalculatorButton />
      </Grid>
    </OptionsRow>
  );
};
