import React from "react";
import styled from "styled-components";
import logoBlack from "../../assets/images/logo-black.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../routes";
import { FaInstagram, FaYoutube, FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.3s opacity ease;
`;

const WIDTH = 225;

const Menu = styled.aside<{ isOpen: boolean }>`
  width: ${WIDTH}px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  transform: translateX(${(props) => (props.isOpen ? 0 : `${WIDTH}px`)});
  transition: 0.3s transform ease;
  padding: 20px 20px 40px 20px;
  overflow-y: auto;
`;

const MenuItem = styled(Link)`
  display: block;
  text-transform: uppercase;
  text-align: right;
  padding: 10px 0;
  color: black;

  :hover,
  :focus {
    color: black;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 80px;
  display: block;
`;

const SocialRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  a {
    margin-left: 20px;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SideMenu: React.FC<IProps> = ({ onClose, isOpen }: IProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Backdrop onClick={onClose} isOpen={isOpen} />
      <Menu isOpen={isOpen}>
        <LogoWrapper>
          <Logo src={logoBlack} alt="Logo" />
        </LogoWrapper>
        <MenuItem to={ROUTES.HOME} onClick={onClose}>
          ANA SƏHİFƏ
        </MenuItem>
        <MenuItem to={ROUTES.ABOUT} onClick={onClose}>
          Ümumi məlumat
        </MenuItem>
        <MenuItem to={ROUTES.BENEFITS} onClick={onClose}>
          Üstünlüklər
        </MenuItem>
        <MenuItem to={ROUTES.project} onClick={onClose}>
          MƏNZİLLƏR
        </MenuItem>
        <MenuItem to={ROUTES.CONDITIONS} onClick={onClose}>
          Ödəniş üsulu
        </MenuItem>
        <MenuItem to={ROUTES.GALLERY} onClick={onClose}>
          QALEREYA
        </MenuItem>
        <MenuItem to={ROUTES.CONTACT} onClick={onClose}>
          ƏLAQƏ
        </MenuItem>

        <SocialRow>
          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
            <FaFacebook color="black" size={30} />
          </a>

          <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
            <FaInstagram color="black" size={30} />
          </a>

          <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
            <FaYoutube color="black" size={30} />
          </a>
        </SocialRow>
      </Menu>
    </div>
  );
};
