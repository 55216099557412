import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1400px;
  width: 100%;
`;

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
}

export const Container: React.FC<IProps> = ({ children, ref, ...rest }: IProps) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};
