import React from "react";
import classes from "./nav_item.module.scss";
import { NavLink, NavLinkProps } from "react-router-dom";

interface IProps extends NavLinkProps {
  title: string;
}

export const NavItem: React.FC<IProps> = ({ title, ...rest }: IProps) => {
  return (
    <NavLink className={classes.nav_item} activeClassName={classes.isActive} {...rest}>
      {title}
    </NavLink>
  );
};
